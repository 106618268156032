<template>
	<layout
		:activeIndex="activeIndex"
		:activeModel.sync="activeModel"
		:topBarItems="topBarItems"
		@setIndex="setIndex"
	>
		<template #left-0>
			<left-menu :list="list" @select="leftMenuSelect"></left-menu>
		</template>
		<template #left-1>
			<b-card>
				<b-card-title>筛选</b-card-title>

				<label>设备名称</label>
				<b-form-input
					placeholder="输入设备名称查询"
					trim
				></b-form-input>
			</b-card>
		</template>
		<template #right-0></template>
		<template #right-1>
			<b-card no-body>
				<b-table
					:current-page="currentPage"
					:fields="fields"
					:items="items"
					:per-page="perPage"
					hover
					striped
				>
					<template v-slot:cell(params)="data">
						<template
							v-for="(param, param_index) in data.item.params"
						>
							<span
								v-if="param.param_name"
								:style="
									param.cur_value === null
										? 'opacity:0.3'
										: ''
								"
							>
								<span class="mx-50" v-if="param_index !== 0"
									>,</span
								>
								<span>{{
									param.param_name.replace(
										data.item.equip_name + "-",
										""
									)
								}}</span
								>:
								<span v-if="param.cur_value === null">N/A</span>
								<span v-else>{{
									param.unit && param.unit.indexOf("||") > -1
										? param.unit.split("||")[
												param.cur_value > 0 ? 1 : 0
										  ]
										: param.cur_value +
										  " " +
										  (param.unit || "")
								}}</span>
							</span>
						</template>
					</template>
					<template #cell(action)="data">
						<div class="text-center">
							<b-button
								v-if="data.item.ip_address"
								variant="warning"
								size="sm"
								>查看</b-button
							>
							<b-button
								variant="success"
								size="sm"
								@click="equip_to_detail_model(data.item)"
								:disabled="
									data.item.detail_model_key ? false : true
								"
								>详情</b-button
							>
						</div>
					</template>
				</b-table>
			</b-card>

			<b-pagination
				v-model="currentPage"
				:per-page="perPage"
				:total-rows="items.length"
				align="right"
				first-number
				hide-goto-end-buttons
				last-number
				next-class="next-item"
				prev-class="prev-item"
			></b-pagination>
		</template>
		<template #right-2>
			<panel-group :show-title="false" group-uid="aa516ecb"></panel-group>
		</template>
	</layout>
</template>

<script>
import Layout from "@/views/front/jsf35/subsystems/layout";
import { reactive, toRefs, watch } from "@vue/composition-api";
import LeftMenu from "@/views/front/jsf35/subsystems/layout/components/LeftMenu";
import store from "@/store";

export default {
	name: "emergency-evacuation",
	components: {
		Layout,
		LeftMenu,
		panelGroup: () => import("@/views/panel-group"),
	},
	setup() {
		// 框架相关参数
		const layoutParams = reactive({
			activeIndex: 0,
			activeModel: "",
			topBarItems: [
				{
					title: "主页",
					model: {
						path: "emergency-evacuation/emergency-evacuation/emergency-evacuation",
						dbIds: ["equipment_"],
					},
				},
				{ title: "设备列表" },
				/* {title: "数据分析"}, */
			],
		});
		//左侧菜单相关参数
		const leftMenuParams = reactive({
			list: [
				{
					title: "F12",
					value: 12,
					model: {
						path: "emergency-evacuation/emergency-evacuation-F11/emergency-evacuation-F11",
						dbIds: ["equipment_"],
					},
				},
				{
					title: "F11",
					value: 11,
					model: {
						path: "emergency-evacuation/emergency-evacuation-F10/emergency-evacuation-F10",
						dbIds: ["equipment_"],
					},
				},
				{
					title: "F10",
					value: 10,
					model: {
						path: "emergency-evacuation/emergency-evacuation-F9/emergency-evacuation-F9",
						dbIds: ["equipment_"],
					},
				},
				{
					title: "F9",
					value: 9,
					model: {
						path: "emergency-evacuation/emergency-evacuation-F8/emergency-evacuation-F8",
						dbIds: ["equipment_"],
					},
				},
				{
					title: "F8",
					value: 8,
					model: {
						path: "emergency-evacuation/emergency-evacuation-F7/emergency-evacuation-F7",
						dbIds: ["equipment_"],
					},
				},
				{
					title: "F7",
					value: 7,
					model: {
						path: "emergency-evacuation/emergency-evacuation-F6/emergency-evacuation-F6",
						dbIds: ["equipment_"],
					},
				},
				{
					title: "F6",
					value: 6,
					model: {
						path: "emergency-evacuation/emergency-evacuation-F5/emergency-evacuation-F5",
						dbIds: ["equipment_"],
					},
				},
				{
					title: "F5",
					value: 5,
					model: {
						path: "emergency-evacuation/emergency-evacuation-F4/emergency-evacuation-F4",
						dbIds: ["equipment_"],
					},
				},
				{
					title: "F3",
					value: 3,
					model: {
						path: "emergency-evacuation/emergency-evacuation-F3/emergency-evacuation-F3",
						dbIds: ["equipment_"],
					},
				},
				{
					title: "F2",
					value: 2,
					model: {
						path: "emergency-evacuation/emergency-evacuation-F2/emergency-evacuation-F2",
						dbIds: ["equipment_"],
					},
				},
				{
					title: "F1",
					value: 1,
					model: {
						path: "emergency-evacuation/emergency-evacuation-F1/emergency-evacuation-F1",
						dbIds: ["equipment_"],
					},
				},
				{
					title: "B1",
					value: -1,
					model: {
						path: "emergency-evacuation/emergency-evacuation-B1/emergency-evacuation-B1",
						dbIds: ["equipment_"],
					},
				},
				{
					title: "B2",
					value: -2,
					model: {
						path: "emergency-evacuation/emergency-evacuation-B2/emergency-evacuation-B2",
						dbIds: ["equipment_"],
					},
				},
				{
					title: "B3",
					value: -3,
					model: {
						path: "emergency-evacuation/emergency-evacuation-B3/emergency-evacuation-B3",
						dbIds: ["equipment_"],
					},
				},
				{
					title: "B5",
					value: -5,
					model: {
						path: "emergency-evacuation/emergency-evacuation-B4/emergency-evacuation-B4",
						dbIds: ["equipment_"],
					},
				},
			],
		});

		// 左侧菜单选中事件
		const leftMenuSelect = (event) => {
			console.log("leftMenuSelectEvent", event);
			// activeModel.value = event.model
			layoutParams.activeModel = event.model;
		};

		const setIndex = (e) => {
			layoutParams.activeIndex = e;
		};

		const data = reactive({
			index: 0,
			status: [
				{
					text: "主页",
					active: true,
				},
				{
					text: "设备列表",
					active: false,
				},
			],
			floor_list: [
				"F12",
				"F11",
				"F10",
				"F9",
				"F8",
				"F7",
				"F6",
				"F5",
				"F3",
				"F2",
				"F1",
				"B1",
				"B2",
				"B3",
				"B5",
			],
			fields: [
				{ label: "ID", key: "equip_id" },
				{ label: "设备名称", key: "equip_name" },
				{ label: "设备编号", key: "equip_meter_code" },
				{ label: "楼层", key: "floor" },
				{ label: "参数状态", key: "params" },
				{ label: "操作", key: "action" },
			],
			items: [],
			perPage: 20,
			currentPage: 1,
			rows: 1,
		});
		watch(
			() => store.state.jsf35.equip_list,
			(list) => {
				data.items = list;
				data.rows = list.length;
			}
		);
		//设备列表跳转到模型大样图
		const equip_to_detail_model = (item) => {
			setIndex(0);
			setTimeout(() => {
				store.commit("jsf35/setEquipSelected", item);
				leftMenuSelect(item);
			}, 1000);
		};

		return {
			...toRefs(layoutParams),
			...toRefs(leftMenuParams),
			leftMenuSelect,
			setIndex,
			...toRefs(data),
			equip_to_detail_model,
		};
	},
};
</script>

<style lang="scss" scoped>
.r_bottom {
	.rb_top {
		height: 90%;

		.is_enabled {
			width: 1.5rem;
			height: 1.5rem;
			border-radius: 50%;
		}
	}

	.rb_bottom {
		height: 10%;
		display: flex;
		align-items: flex-end;
	}
}
</style>
